import React from 'react';
import Layout from '../layouts/Layout';
import SEO from '../components/core/Seo';
import BlankPage from '../templates/BlankPage';
import InfoPage from '../templates/InfoPage';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <InfoPage span="page not found" title="404">
      <BlankPage>
        <p>Sorry, we can't seem to find the page you're looking for.</p>
        <p>Go to <Link to="/">homepage</Link></p>
      </BlankPage>
    </InfoPage>
  </Layout>
);

export default NotFoundPage;
